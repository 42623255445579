<template>
  <b-container>
    <b-row>
      <b-col md="6" class="d-flex align-items-center">
        <div class="flex-fill">
          <div style="border: 4px solid #ff5e15; width: 15%" class="mb-2"></div>
          <h1 class="text-colorBlack font-weight-bolder">
            Buy Sell any items <br />
            with Alistia, Quickly!
          </h1>
          <h4 class="mt-3">
            We all love a good deal, who doesn't? But how do you <br />
            find the best bargains near you? The answer is simple. <br />
            All you need to do is use this tool . It's just.
          </h4>
          <div class="mt-3 custom-filter-border p-2">
            <CustomButton
              title="SUBMIT LISTING"
              firstIcon="SearchIcon"
              secondIcon="ChevronRightIcon"
              titleBackgroundColor="#ff5e14"
              iconBackgroundColor="#e84a02"
              titleHoverBackgroundColor="#00bff5"
              iconHoverBackgroundColor="#07a0cb"
            />
          </div>
          <h4 class="mt-3">
            <span class="font-weight-bolder text-colorBlack"
              >Recent Searches:</span
            >
            Apartment, iPhone, Camera
          </h4>
        </div>
      </b-col>
      <b-col md="6" class="d-flex justify-content-end">
        <div class="custom-hero-img-border d-inline-block custom-hero-img">
          <b-img src="@/assets/images/icons/hero.jpg" height="500"></b-img>
        </div>
        <b-img
          src="@/assets/images/icons/rocket.png"
          class="custom-rocket-img position-absolute custom-move-up-down-animation"
        ></b-img>
        <b-img
          src="@/assets/images/icons/score.png"
          class="custom-score-img position-absolute custom-move-up-down-animation"
        ></b-img>
        <b-img
          src="@/assets/images/icons/items-listed.png"
          class="custom-items-listed-img position-absolute custom-move-up-down-animation"
        ></b-img>
        <b-img
          src="@/assets/images/icons/items-sold.png"
          class="custom-items-sold-img position-absolute custom-move-up-down-animation"
        ></b-img>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CustomButton from "@/components/ui/CustomButton.vue";

export default {
  components: {
    CustomButton,
  },
};
</script>

<style scoped>
.custom-filter-border {
  border: 8px solid lightgray;
}
.custom-hero-img-border {
  border-left: 4px solid orangered;
}
.custom-hero-img {
  z-index: 1;
}
.custom-rocket-img {
  bottom: 0;
  left: 0;
  z-index: 2;
}
.custom-score-img {
  bottom: -100px;
  left: 30%;
  z-index: 2;
}
.custom-items-listed-img {
  top: 0;
  right: 0;
  z-index: 2;
}
.custom-items-sold-img {
  top: 0;
  left: 0;
  z-index: 2;
}
.custom-move-up-down-animation {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
</style>
