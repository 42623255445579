<template>
  <div class="bg-lightOrange" style="margin-top: 180px">
    <b-container>
      <div>
        <div class="text-center">
          <h4 class="font-weight-bolder text-primary pt-5">
            Explore All Offer For Business
          </h4>
          <h1 class="py-1 font-weight-bolder text-colorBlack">
            Featured Items
          </h1>
        </div>
        <div class="mt-5">
          <b-row>
            <b-col md="3">
              <b-card no-body class="text-center my-card">
                <div class="pb-2">
                  <b-img
                    src="@/assets/images/featureditem/vehicle-b.svg"
                    width="200"
                    height="120"
                    alt="logo"
                    class="pt-3"
                  />
                </div>
                <div class="py-2 w-50 my-border"></div>
                <div class="py-3">
                  <h2 class="font-weight-bolder text-colorBlack">Vehicles</h2>
                </div>
              </b-card>
            </b-col>
            <b-col md="3">
              <b-card no-body class="text-center my-card">
                <div class="pb-2">
                  <b-img
                    src="@/assets/images/featureditem/vehicle-b.svg"
                    width="200"
                    height="120"
                    alt="logo"
                    class="pt-3"
                  />
                </div>
                <div class="py-2 w-50 my-border"></div>
                <div class="py-3">
                  <h2 class="font-weight-bolder text-colorBlack">Jobs</h2>
                </div>
              </b-card>
            </b-col>
            <b-col md="3">
              <b-card no-body class="text-center my-card">
                <div class="pb-2">
                  <b-img
                    src="@/assets/images/featureditem/vehicle-b.svg"
                    width="200"
                    height="120"
                    alt="logo"
                    class="pt-3"
                  />
                </div>
                <div class="py-2 w-50 my-border"></div>
                <div class="py-3">
                  <h2 class="font-weight-bolder text-colorBlack">Fashion</h2>
                </div>
              </b-card>
            </b-col>
            <b-col md="3">
              <b-card no-body class="text-center my-card">
                <div class="pb-2">
                  <b-img
                    src="@/assets/images/featureditem/vehicle-b.svg"
                    width="200"
                    height="120"
                    alt="logo"
                    class="pt-3"
                  />
                </div>
                <div class="py-2 w-50 my-border"></div>
                <div class="py-3">
                  <h2 class="font-weight-bolder text-colorBlack">Services</h2>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <div
          class="text-center"
          style="font-size: 1.2rem; padding: 40px 200px 0px 200px"
        >
          <p class="pb-5 font-weight-normal text-colorBlack">
            Tempor incididunt ut laboret dolore magna aliquaut enimad dui
            exrciton. Lorem ipsum dolor sit amet dui consectetur adipisicing
            incididunt labore dolore mag aliqua quis nostrud.
          </p>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.my-card {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.my-card:hover {
  background-color: #ff5e15;
  transform: translateY(-26px);
}
.my-card:hover .my-border {
  border-right: 2px solid white;
}
.my-border {
  border-right: 2px solid #ff5e15;
}
</style>
