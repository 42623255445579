<template>
  <div>
    <Navbar />
    <Hero />
    <PopularListing />
    <FeaturedItem />
    <WhyChooseAlistia />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/home/Navbar.vue";
import Hero from "@/components/home/Hero.vue";
import PopularListing from "@/components/home/PopularListing.vue";
import FeaturedItem from "@/components/home/FeaturedItem.vue";
import WhyChooseAlistia from "@/components/home/WhyChooseAlistia.vue";
import Footer from "@/components/home/Footer.vue";

export default {
  components: {
    Navbar,
    Hero,
    PopularListing,
    FeaturedItem,
    WhyChooseAlistia,
    Footer,
  },
};
</script>

<style></style>
