<template>
  <b-container>
    <div style="margin-top: 180px">
      <b-row class="d-flex align-items-center">
        <b-col md="6" class="d-flex justify-content-start">
          <div class="custom-hero-img-border d-inline-block custom-hero-img">
            <b-img src="@/assets/images/icons/hero.jpg"></b-img>
          </div>
        </b-col>
        <b-col md="6">
          <div>
            <div
              style="border: 3px solid #ff5e15; width: 15%"
              class="mb-2"
            ></div>
            <h4 class="pb-50 font-weight-bolder text-primary">
              Cupidas Non Proident Sunt
            </h4>
            <h1 class="m-0 p-0 pb-50 font-weight-bolder text-colorBlack">
              We provide is the most
            </h1>
            <h1 class="font-weight-bolder text-colorBlack">
              popular listings directory
            </h1>
            <p class="pt-2 text-colorBlack" style="font-size: 1.2rem">
              Tempor incididunt ut laboret dolore magna aliquaut enimad dui
              exrciton. Lorem ipsum dolor sit amet dui consectetur adipisicing
              incididunt labore dolore mag aliqua quis sit amet dui consectetur
              adipisicing incididunt labore dolore mag aliqua quis nostrud.
            </p>
          </div>
          <div class="pt-4">
            <feather-icon
              icon="CheckSquareIcon"
              size="24"
              class="text-primary"
            />
            <span class="ml-1 text-colorBlack" style="font-size: 1.2rem"
              >Laboret dolore magna aliquaut</span
            >
          </div>
          <div class="pt-2">
            <feather-icon
              icon="CheckSquareIcon"
              size="24"
              class="text-primary"
            />
            <span class="ml-1 text-colorBlack" style="font-size: 1.2rem"
              >Laboret dolore magna aliquaut</span
            >
          </div>
          <div class="pt-2">
            <feather-icon
              icon="CheckSquareIcon"
              size="24"
              class="text-primary"
            />
            <span class="ml-1 text-colorBlack" style="font-size: 1.2rem"
              >Laboret dolore magna aliquaut</span
            >
          </div>
          <div class="pt-2">
            <feather-icon
              icon="CheckSquareIcon"
              size="24"
              class="text-primary"
            />
            <span class="ml-1 text-colorBlack" style="font-size: 1.2rem"
              >Laboret dolore magna aliquaut</span
            >
          </div>
        </b-col>
      </b-row>
      <div style="margin-top: 120px" class="text-center">
        <h4 class="font-weight-bolder text-primary">
          Explore All Offer For Business
        </h4>
        <h1 class="py-1 font-weight-bolder text-colorBlack">
          Why Choose Alistia
        </h1>
        <b-row>
          <b-col md="4" class="py-3">
            <div class="d-flex align-items-center justify-content-start">
              <div>
                <b-img
                  src="@/assets/images/featureditem/vehicle.svg"
                  width="180"
                  height="120"
                  alt="logo"
                />
              </div>

              <div class="text-left ml-1">
                <h2 class="font-weight-bolder text-colorBlack">Vehicles</h2>
                <p class="text-colorBlack">
                  Tempor incididunt ut laboret dolore magna aliquaut enimad dui
                  exrciton.
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" class="py-3">
            <div class="d-flex align-items-center justify-content-start">
              <div>
                <b-img
                  src="@/assets/images/featureditem/vehicle.svg"
                  width="180"
                  height="120"
                  alt="logo"
                />
              </div>

              <div class="text-left ml-1">
                <h2 class="font-weight-bolder text-colorBlack">Vehicles</h2>
                <p class="text-colorBlack">
                  Tempor incididunt ut laboret dolore magna aliquaut enimad dui
                  exrciton.
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" class="py-3">
            <div class="d-flex align-items-center justify-content-start">
              <div>
                <b-img
                  src="@/assets/images/featureditem/vehicle.svg"
                  width="180"
                  height="120"
                  alt="logo"
                />
              </div>

              <div class="text-left ml-1">
                <h2 class="font-weight-bolder text-colorBlack">Vehicles</h2>
                <p class="text-colorBlack">
                  Tempor incididunt ut laboret dolore magna aliquaut enimad dui
                  exrciton.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col md="4" class="py-3">
            <div class="d-flex align-items-center justify-content-start">
              <div>
                <b-img
                  src="@/assets/images/featureditem/vehicle.svg"
                  width="180"
                  height="120"
                  alt="logo"
                />
              </div>

              <div class="text-left ml-1">
                <h2 class="font-weight-bolder text-colorBlack">Vehicles</h2>
                <p class="text-colorBlack">
                  Tempor incididunt ut laboret dolore magna aliquaut enimad dui
                  exrciton.
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" class="py-3">
            <div class="d-flex align-items-center justify-content-start">
              <div>
                <b-img
                  src="@/assets/images/featureditem/vehicle.svg"
                  width="180"
                  height="120"
                  alt="logo"
                />
              </div>

              <div class="text-left ml-1">
                <h2 class="font-weight-bolder text-colorBlack">Vehicles</h2>
                <p class="text-colorBlack">
                  Tempor incididunt ut laboret dolore magna aliquaut enimad dui
                  exrciton.
                </p>
              </div>
            </div>
          </b-col>
          <b-col md="4" class="py-3">
            <div class="d-flex align-items-center justify-content-start">
              <div>
                <b-img
                  src="@/assets/images/featureditem/vehicle.svg"
                  width="180"
                  height="120"
                  alt="logo"
                />
              </div>

              <div class="text-left ml-1">
                <h2 class="font-weight-bolder text-colorBlack">Vehicles</h2>
                <p class="text-colorBlack">
                  Tempor incididunt ut laboret dolore magna aliquaut enimad dui
                  exrciton.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {};
</script>

<style></style>
