<template>
  <b-container>
    <div style="margin-top: 180px">
      <div class="text-center">
        <h4 class="font-weight-bolder text-primary">
          Best Bargains Nearby In Real-Time
        </h4>
        <h1 class="py-1 font-weight-bolder text-colorBlack">
          Popular Listings Now
        </h1>
      </div>
      <div>
        <div class="mt-5">
          <b-tabs content-class="mt-5" align="center" pills>
            <b-tab title="All" active>
              <b-card-group deck>
                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder text-colorBlack"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text>
                    <feather-icon
                      icon="MapPinIcon"
                      size="17"
                      class="mr-50 text-primary"
                    />
                    <span class="font-weight-normal text-colorBlack"
                      >Washington DC,USA</span
                    >
                  </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder text-primary">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder text-colorBlack"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text>
                    <feather-icon
                      icon="MapPinIcon"
                      size="17"
                      class="mr-50 text-primary"
                    />
                    <span class="font-weight-normal text-colorBlack"
                      >Washington DC,USA</span
                    >
                  </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder text-primary">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder text-colorBlack"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text>
                    <feather-icon
                      icon="MapPinIcon"
                      size="17"
                      class="mr-50 text-primary"
                    />
                    <span class="font-weight-normal text-colorBlack"
                      >Washington DC,USA</span
                    >
                  </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder text-primary">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder text-colorBlack"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text>
                    <feather-icon
                      icon="MapPinIcon"
                      size="17"
                      class="mr-50 text-primary"
                    />
                    <span class="font-weight-normal text-colorBlack"
                      >Washington DC,USA</span
                    >
                  </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder text-primary">$ 3,250.00</h1>
                  </template>
                </b-card>
              </b-card-group>
            </b-tab>
            <b-tab title="Devices">
              <b-card-group deck>
                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>
              </b-card-group></b-tab
            >
            <b-tab title="Animals">
              <b-card-group deck>
                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>
              </b-card-group></b-tab
            >
            <b-tab title="For Kids">
              <b-card-group deck>
                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>
              </b-card-group></b-tab
            >
            <b-tab title="Vehicles">
              <b-card-group deck>
                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>

                <b-card
                  img-src="@/assets/images/popularlisting/images.jpg"
                  img-alt="Image"
                  img-top
                >
                  <b-card-title class="font-weight-bolder"
                    >Oculus Quest</b-card-title
                  >
                  <b-card-text> Washington DC,USA </b-card-text>
                  <template #footer>
                    <h1 class="font-weight-bolder">$ 3,250.00</h1>
                  </template>
                </b-card>
              </b-card-group></b-tab
            >
          </b-tabs>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {};
</script>

<style></style>
